import React, { useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../axios";

function RequestQuote() {
  const [formData, setFormData] = useState({});
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(false);

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
  };

  const sendEmail = () => {
    axios
      .post("/requestquote", formData)
      .then(() => {
        setMailSent(true);
        resetForm();
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const resetForm = () => {
    document.getElementById("contact-form").reset();
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-start footer-fix">
        <div className="col-lg-8 mt-1 mt-lg-5 mb-5 mb-lg-0 text-center p-5 border border-dark rounded bg-secondary text-white">
          <h1 className="font-weight-bold">Request Quote</h1>
          <p>Do you have a website or application that needs built?</p>
          <p>
            Fill out the form and provide as much detail as you can for your
            project. I will review the information and get back to you as soon
            as possible. I look forward to working with you!
          </p>
          <form id="contact-form" onSubmit={handleSubmit} method="POST">
            <div className="form-row my-2">
              <div className="col-12 col-lg-6 my-1 my-lg-0">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="text"
                  name="fname"
                  placeholder="First Name*"
                  pattern="(^$)|(^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF][-a-zA-Z.' \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]{1,}$)"
                  required
                />
              </div>
              <div className="col-12 col-lg-6 my-1 my-lg-0">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="text"
                  name="lname"
                  placeholder="Last Name*"
                  pattern="(^$)|(^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF][-a-zA-Z.' \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]{1,}$)"
                  required
                />
              </div>
            </div>
            <div className="form-row my-2">
              <div className="col-12 col-lg-6 my-1 my-lg-0">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="tel"
                  name="phone"
                  placeholder="111-222-333"
                  pattern="(^$)|(^\+?[1]?[-. ]?\(?[\d]{3}\)?[-. ]?[\d]{3}[-. ]?[\d]{4}[ a-zA-Z0-9.:]{0,20}$)"
                />
              </div>
              <div className="col-12 col-lg-6 my-1 my-lg-0">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="email"
                  name="email"
                  placeholder="Email*"
                  pattern="(^$)|(^.*(\S+).*$)"
                  required
                />
              </div>
            </div>
            <div className="form-row my-2 align-items-center">
              <div className="col-12 col-lg-3 my-1 my-lg-0 order-1">
                <select
                  className="custom-select"
                  onChange={updateInput}
                  name="projectType"
                >
                  <option value="Website" name="Website">
                    Website
                  </option>
                  <option value="Application" name="Application">
                    Application
                  </option>
                  <option value="Other" name="Other">
                    Other
                  </option>
                </select>
              </div>
              <div className="col-12 col-lg-3 my-1 my-lg-0 order-2">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="text"
                  name="budget"
                  placeholder="Project Budget* (Hourly or Fixed Price)"
                  required
                />
              </div>
              <div className="col-12 col-lg-3 my-1 my-lg-0 order-4 order-lg-3">
                <input
                  className="form-control"
                  onChange={updateInput}
                  type="date"
                  name="completionDate"
                  required
                />
              </div>
              <div className="col-12 col-lg-3 my-1 my-lg-0 order-3 order-lg-3">
                <small>Anticipated Project Completion Date.</small>
              </div>
            </div>
            <textarea
              className="form-control my-2"
              onChange={updateInput}
              rows="10"
              name="projectDetails"
              placeholder="Project Details*"
            ></textarea>
            <div className="">
              {mailSent && (
                <div className="border border-success my-2">
                  Thank you for contacting us!
                </div>
              )}
              {error && (
                <div className="border border-warning my-2">
                  Message failed to send!
                </div>
              )}
            </div>
            <input
              className="btn btn-primary rounded-pill px-4 float-left"
              id="submitButton"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestQuote;
